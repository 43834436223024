<style lang="less">
// 滚动条样式
// ::-webkit-scrollbar {
//   background: transparent;
//   width: 0;
//   height: 10px;
// }
// ::-webkit-scrollbar-thumb {
//   border-radius: 5px;
//   background-color: #e1e1e1;
//   width: 0;
//   height: 6px;
//   border: 2px solid transparent;
//   background-clip: content-box;
// }
// ::-webkit-scrollbar-thumb:hover {
//   background-color: #c1c1c1;
// }
// 全局公共样式
body {
  margin: 0;
}
a {
  text-decoration: none;
}
.wrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
}
// 页面样式
#app {
  .header {
    width: 100%;
    height: 80px;
    background: #fff;
    position: sticky;
    top: 0;
    z-index: 999;
    .wrap {
      margin: auto;
      max-width: 1400px;
      height: 100%;
      justify-content: start;
      padding: 0 30px;
      .logo {
        height: 50px;
      }
    }
  }
  .header_down {
    box-shadow: 0 2px 10px 0 rgb(0 0 0 / 10%);
  }
  .content {
    padding: 2.81vw 0 8.33vw; // 54px 0 160px
    .content__title {
      padding: 26px;
      text-align: center;
      font-size: 34px;
      font-weight: bold;
    }
    .s1 h1::before {
      background: #419BFE;
    }
    .s2 h1::before {
      background: #D83434;
    }
    .s3 h1::before {
      background: #69B053;
    }
    .s4 h1::before {
      background: #FF7C00;
    }
    .s5 h1::before {
      background: #F66EE9;
    }
  }
  .content-bg {
    background: #F7F8FB;
  }
  .tools {
    text-align: center;
    padding: 2.81vw 0 8.33vw;
    background: #F7F8FB;
    .tools__title {
      padding: 26px;
      text-align: center;
      font-size: 34px;
      font-weight: bold;
      display: inline-block;
    }
    .tools__box {
      margin: 35px auto 0;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      max-width: 1090px;
      >img {
        margin: 25px 20px;
        width: 178px;
        height: 94px;
        object-fit: contain;
        transition: .2s;
        cursor: pointer;
      }
      >img:hover {
        transform: scale(1.1);
      }
    }
  }
  .scenic {
    text-align: center;
    padding: 2.81vw 0 8.33vw;
    background: url('@/assets/img/scenic_bg.png') no-repeat center;
    background-size: cover;
    .scenic__title {
      padding: 26px;
      text-align: center;
      font-size: 34px;
      font-weight: bold;
      display: inline-block;
    }
    .scenic__box {
      margin: 40px auto 0;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      max-width: 1185px;
      >div {
        margin: 20px 12px;
        display: flex;
        flex-direction: column;
        align-items: center;
        >img {
          width: 213px;
          height: 128px;
          object-fit: cover;
          transition: .2s;
          cursor: pointer;
        }
        >img:hover {
          transform: scale(1.1);
        }
        >span {
          margin-top: 12px;
          font-size: 16px;
          color: rgba(0,0,0,0.8);
        }
      }
    }
  }
  .footer {
    width: 100%;
    height: 70px;
    line-height: 16px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    color: #ADADAD;
    padding: 0 30px;
    box-sizing: border-box;
    >a {
      color: #ADADAD;
    }
    >a:hover {
      color: #1890ff;
    }
    >i:hover {
      cursor: pointer;
    }
  }
  @media (min-width: 1440px) {
    .wrap {
      justify-content: center;
      .left {
        margin-right: 10vw;
      }
    }
  }
}
</style>

<template>
  <div class="header" :class="{'header_down': headerDown}">
    <div class="wrap">
      <img class="logo" src="@/assets/logo/数科云.png" alt="">
    </div>
  </div>
  <div class="content" :class="{'content-bg': wrapIndex%2 == 0}" v-for="(wrapItem,wrapIndex) in wrapList" :key="wrapIndex">
    <div class="content__title">{{wrapItem.title}}</div>
    <wrap :class="projectItem.style" :projectItem="projectItem" v-for="(projectItem,projectIndex) in wrapItem.projectList" :key="projectIndex"></wrap>
  </div>
  <div class="tools">
    <span class="tools__title">运维工具</span>
    <div class="tools__box">
      <img :src="item.img" v-for="(item,index) in toolsList" :key="index" @click="toUrl(item.url)">
    </div>
  </div>
  <div class="scenic">
    <span class="scenic__title">合作景区</span>
    <div class="scenic__box">
      <div v-for="(item,index) in scenicList" :key="index">
        <img :src="item.img" @click="toUrl(item.url)">
        <span>{{item.name}}</span>
      </div>
    </div>
  </div>
  <div class="footer" v-if="ishead">
    <span>&copy;&nbsp;2001-{{ date }}</span>
    <a target="_blank" href="http://www.hqshuke.com">「环球数科集团」</a>
    <span>版权所有&nbsp;&nbsp;|&nbsp;&nbsp;</span>
    <!-- <a target="_blank" href="https://a5iu5xlzwa.feishu.cn/sheets/shtcnOhvW0jYIYk4IVHbrk7ru5g"
      class="fa fa-file-excel-o" title="账号"></a>
    <span>&nbsp;&nbsp;</span>
    <a target="_blank" href="https://git.shukeyun.com" class="fa fa-git-square" title="GitLab"></a>
    <span>&nbsp;&nbsp;</span> -->
    <a target="_blank" href="https://beian.miit.gov.cn">粤&nbsp;ICP&nbsp;备&nbsp;09156180&nbsp;号</a>
  </div>
</template>

<script>
import wrap from './components/Wrap.vue'
export default {
  name: 'App',
  components: {
    wrap
  },
  data() {
    return {
      ishead: true,
      headerDown: false,
      date: '',
      wrapList: [
        {
          title: '基础能力',
          projectList: [
            // 数据分析
            {
              style: 's1',
              title: '数据与分析',
              content: '提供数据库查询、大规模计算、数据报表等服务的综合智能平台，提高客户数据分析效率。',
              logoList: [
                // 灵狐
                {
                  name: 'Deepfox 灵狐',
                  img: require('@/assets/logo/灵狐.png'),
                  url: 'https://prod.shukeyun.com/data/datafox/#/',
                  iconList: [],
                },
                // 分析引擎
                {
                  name: '大数据分析引擎',
                  img: require('./assets/logo/分析引擎.png'),
                  url: 'https://redash-prod.shukeyun.com/',
                  iconList: [],
                },
              ]
            },
            // 支付安全
            {
              style: 's2',
              title: '支付与安全',
              content: '结合区块链技术，保证交易结算的安全性，有效整合景区和涉旅企业的资金结算问题。',
              logoList: [
                // 智旅链
                {
                  name: '智旅链',
                  img: require('@/assets/logo/智旅链.png'),
                  url: '',
                  iconList: [
                    {
                      name: 'BDS 区块链\n浏览器',
                      img: require('./assets/icon/BDS 区块链浏览器.png'),
                      url: 'https://explorer.zhilvlian.com',
                    },
                    {
                      name: 'BDS NFT\n浏览器',
                      img: require('./assets/icon/BDS NFT 浏览器.png'),
                      url: 'https://nft.zhilvlian.com/#/home',
                    },
                    {
                      name: '数字藏品\nH5',
                      img: require('./assets/icon/数字藏品 H5.png'),
                      url: 'https://nft.zhilvlian.com/blockchain/h5/#/',
                    },
                    {
                      name: '数字藏品\n管理后台',
                      img: require('./assets/icon/数字藏品管理后台.png'),
                      url: 'https://nft.zhilvlian.com/blockchain/management/#/',
                    },
                  ],
                },
                // 云联网络
                {
                  name: '云联网络',
                  img: require('./assets/logo/云联网络.png'),
                  url: '',
                  iconList: [
                    {
                      name: '个人中心',
                      img: require('./assets/icon/个人中心.png'),
                      url: 'https://www.upaypal.cn/personal/#/register?_k=8u2g9w',
                    },
                    {
                      name: '商户中心',
                      img: require('./assets/icon/商户中心.png'),
                      url: 'https://www.upaypal.cn/merchants',
                    }
                  ],
                },
              ]
            },
          ]
        },
        {
          title: '行业 + 云',
          projectList: [
            // 生态监管
            {
              style: 's3',
              title: '生态与监管',
              content: '融合卫星通信、大数据等技术，构建全方位、多层次的生态监测平台，提高应急处置效率。',
              logoList: [
                // 监管
                {
                  name: '智慧旅游监管平台',
                  img: require('@/assets/logo/朝天旅游.png'),
                  url: 'http://chaotian.zhlyjg.cn',
                  iconList: [],
                },
                // 监管
                {
                  name: '生态监测与灾害预警\n管理平台',
                  img: require('@/assets/logo/生态监测.png'),
                  url: 'http://yanjinghe.zhlyjg.cn',
                  iconList: [],
                },
                // 森林防火
                {
                  name: '森林防火监测预警和\n指挥系统',
                  img: require('@/assets/logo/森林防火.png'),
                  url: '',
                  iconList: [
                    {
                      name: '数据大屏',
                      img: require('@/assets/icon/数据大屏.png'),
                      url: 'https://prod.shukeyun.com/fire/web/',
                    },
                    {
                      name: '管理后台',
                      img: require('@/assets/icon/管理后台.png'),
                      url: 'https://test.shukeyun.com/fire/management/#/',
                    },
                    {
                      name: 'APP 下载',
                      img: require('@/assets/qrCode/森林防火.png'),
                      url: '',
                    },
                  ],
                },
              ]
            },
            // 城市管理
            {
              style: 's4',
              title: '城市与管理',
              content: '基于全域数字底座，利用数实融合引擎，提供更方便快捷的管理，给用户带来更加舒适的“数字化”生活体验。',
              logoList: [
                // 车无忌
                {
                  name: '车无忌',
                  img: require('./assets/logo/车无忌.png'),
                  url: '',
                  iconList: [
                    {
                      name: '管理后台',
                      img: require('./assets/icon/管理后台_.png'),
                      url: 'http://www.parkingfree.cn/o2o-admin',
                    },
                    {
                      name: '物业后台',
                      img: require('./assets/icon/物业后台.png'),
                      url: 'http://www.parkingfree.cn/o2o-cp',
                    }
                  ],
                },
                // 智慧社区
                {
                  name: '智慧社区系统',
                  img: require('@/assets/logo/印象石灰.png'),
                  url: 'https://www.shgzsq.com/',
                  iconList: [],
                },
                // 母婴室
                {
                  name: '智慧母婴室',
                  img: require('@/assets/logo/智慧母婴室.png'),
                  url: '',
                  iconList: [
                    {
                      name: '运营中心',
                      img: require('@/assets/icon/运营中心.png'),
                      url: 'http://mng.quanzilx.com',
                    },
                    {
                      name: '监管中心',
                      img: require('@/assets/icon/监管中心.png'),
                      url: 'http://jgf.quanzilx.com',
                    },
                    {
                      name: '管理中心',
                      img: require('@/assets/icon/管理中心.png'),
                      url: 'http://my.quanzilx.com',
                    },
                    {
                      name: '母婴室地图',
                      img: require('@/assets/qrCode/母婴室.png'),
                      url: '',
                    }
                  ],
                },
              ]
            },
            // 文化旅游
            {
              style: 's5',
              title: '文化与旅游',
              content: '深入旅游行业，提供旅游目的地数字化建设与运营的解决方案，打造全场景目的地云服务平台。',
              logoList: [
                {
                  name: '智慧文旅',
                  img: require('@/assets/logo/智慧文旅.png'),
                  url: '',
                  iconList: [
                    // 慧旅云
                    {
                      name: '',
                      img: require('@/assets/logo/慧旅云.png'),
                      url: 'https://prod.shukeyun.com/cas/login/#/login?appId=xZZLVvBpD79TQVxPWOnd',
                    },
                    // 慧景云
                    {
                      name: '',
                      img: require('@/assets/logo/慧景云.png'),
                      url: 'https://huijingyun.net/#/dtbyc/welcome',
                    },
                    // 易旅通
                    {
                      name: '',
                      img: require('@/assets/logo/易旅通.png'),
                      url: 'https://prod.shukeyun.com/cas/login/#/login?appId=yc3fvV45yEWablblgUoq',
                    },
                    // 易旅宝
                    {
                      name: '',
                      img: require('@/assets/logo/易旅宝.png'),
                      url: '',
                      iconList: [
                        {
                          name: '易旅宝',
                          img: require('@/assets/qrCode/易旅宝.png'),
                          url: '',
                        }
                      ],
                    },
                  ]
                },
                // 检票平台
                {
                  name: '检票平台',
                  img: require('@/assets/logo/检票平台.png'),
                  url: '',
                  iconList: [
                    {
                      name: '检票平台',
                      img: require('@/assets/qrCode/检票.png'),
                      url: '',
                    }
                  ],
                },
              ]
            }
          ]
        },
      ],
      scenicList: [
        {
          name: '龙门阁',
          img: require('@/assets/scenic/jq_01.png'),
          url: 'http://lmg.yeahtour.cn'
        },
        {
          name: '水磨沟',
          img: require('@/assets/scenic/jq_02.png'),
          url: 'http://smg.yeahtour.cn'
        },
        {
          name: '明月峡',
          img: require('@/assets/scenic/jq_03.png'),
          url: 'http://myx.yeahtour.cn'
        },
        {
          name: '曾家山',
          img: require('@/assets/scenic/jq_04.png'),
          url: 'http://zjs.yeahtour.cn'
        },
        {
          name: '皇泽寺',
          img: require('@/assets/scenic/jq_05.png'),
          url: 'http://hzs.yeahtour.cn'
        },
        {
          name: '东河口',
          img: require('@/assets/scenic/jq_06.png'),
          url: 'http://dhk.yeahtour.cn'
        },
        {
          name: '唐家河',
          img: require('@/assets/scenic/jq_07.png'),
          url: 'http://tjh.yeahtour.cn'
        },
        {
          name: '梨博园',
          img: require('@/assets/scenic/jq_08.png'),
          url: 'http://lby.yeahtour.cn'
        },
        {
          name: '剑门关',
          img: require('@/assets/scenic/jq_09.png'),
          url: 'http://jmg.yeahtour.cn'
        },
        {
          name: '千佛崖',
          img: require('@/assets/scenic/jq_10.png'),
          url: 'http://qfy.yeahtour.cn'
        },
        {
          name: '卡尔城',
          img: require('@/assets/scenic/jq_11.png'),
          url: 'http://kec.yeahtour.cn'
        },
        {
          name: '米仓山大峡谷',
          img: require('@/assets/scenic/jq_12.png'),
          url: 'http://dxg.yeahtour.cn'
        },
        {
          name: '鼓城山',
          img: require('@/assets/scenic/jq_13.png'),
          url: 'http://mcs.yeahtour.cn'
        },
        {
          name: '旺苍县文化馆',
          img: require('@/assets/scenic/jq_14.png'),
          url: 'http://wcwhg.yeahtour.cn'
        },
        {
          name: '旺苍县图书馆',
          img: require('@/assets/scenic/jq_15.png'),
          url: 'http://wctsg.yeahtour.cn'
        },
        {
          name: '苍溪县文化馆',
          img: require('@/assets/scenic/jq_16.png'),
          url: 'http://cxwhg.yeahtour.cn'
        },
        {
          name: '苍溪县图书馆',
          img: require('@/assets/scenic/jq_17.png'),
          url: 'http://cxtsg.yeahtour.cn'
        },
        {
          name: '剑阁县图书馆',
          img: require('@/assets/scenic/jq_18.png'),
          url: 'http://jgtsg.yeahtour.cn'
        },
        {
          name: '荆州园博园',
          img: require('@/assets/scenic/jq_19.png'),
          url: 'http://www.jzyby.com'
        }
      ],
      toolsList: [
        {
          img: require('@/assets/tools/01.png'),
          url: 'http://10.8.13.68'
        },
        {
          img: require('@/assets/tools/02.png'),
          url: 'https://10.8.1.221:31199'
        },
        {
          img: require('@/assets/tools/03.png'),
          url: 'http://git.shukeyun.com'
        },
        {
          img: require('@/assets/tools/04.png'),
          url: 'http://prod-grafana.shukeyun.com'
        },
        {
          img: require('@/assets/tools/05.png'),
          url: 'http://grafana-prod.shukeyun.com'
        },
        {
          img: require('@/assets/tools/06.png'),
          url: ''
        },
        {
          img: require('@/assets/tools/07.png'),
          url: 'http://10.8.13.13/zabbix/'
        },
        {
          img: require('@/assets/tools/08.png'),
          url: 'http://rancher.shukeyun.com'
        },
        {
          img: require('@/assets/tools/09.png'),
          url: 'https://10.8.11.60:8443'
        },
        {
          img: require('@/assets/tools/10.png'),
          url: 'http://minio-prod.shukeyun.com'
        },
        {
          img: require('@/assets/tools/11.png'),
          url: ''
        },
        {
          img: require('@/assets/tools/12.png'),
          url: 'http://reg.shukeyun.com'
        },
        {
          img: require('@/assets/tools/13.png'),
          url: ''
        },
        {
          img: require('@/assets/tools/14.png'),
          url: 'http://10.8.11.55:8080/'
        },
        {
          img: require('@/assets/tools/15.png'),
          url: ''
        },
        {
          img: require('@/assets/tools/16.png'),
          url: 'http://grafana-prod.shukeyun.com'
        },
        {
          img: require('@/assets/tools/17.png'),
          url: 'https://redash-prod.shukeyun.com'
        },
        {
          img: require('@/assets/tools/18.png'),
          url: ''
        },
        {
          img: require('@/assets/tools/19.png'),
          url: 'http://192.168.90.143:8069/'
        },
        {
          img: require('@/assets/tools/20.png'),
          url: ''
        },
      ]
    }
  },
  created() {
    this.ishead = location.search != '?nohead'
    window.addEventListener('scroll', () => {
      let top = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0
      this.headerDown = top > 100
    })
    this.date = new Date().getFullYear()
  },
  methods: {
    toUrl(url) {
      if (url) {
        window.open(url)
      } else {
        alert('暂无链接！')
      }
    }
  }
}
</script>

<style lang="less" scoped>
  .wrap {
    margin-top: 1.98vw; // 38px
    .left {
      margin: 30px;
      // margin-right: 18.75vw; // 360px
      max-width: 570px;
      >h1 {
        font-size: 26px;
        font-weight: bold;
        color: #222;
        display: flex;
        align-items: center;
        line-height: 26px;
      }
      >h1::before {
        content: '';
        width: 5px;
        height: 26px;
        display: inline-block;
        margin-right: 10px;
      }
      >span {
        font-size:  16px;
        color: #999;
        line-height: 30px;
      }
    }
    .right {
      width: 486px;
      display: flex;
      flex-wrap: wrap;
      .logo-wrap {
        margin: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
        >img {
          padding: 36px;
          width: 90px;
          height: 90px; 
          transition: .3s;
        }
        pre {
          font-family: none;
          font-size: 14px;
          color: #333;
          transition: .5s;
          position: absolute;
          top: 75%;
          left: 50%;
          transform: translateX(-50%);
          text-align: center;
        }
        >object {
          pointer-events: auto;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          opacity: 0;
          transition: 0.5s;
          width: 218px;
          display: none;
          flex-wrap: wrap;
          .icon-wrap {
            margin: 15px;
            display: flex;
            flex-direction: column;
            align-items: center;
            position: relative;
            >img {
              margin: 13px 0;
              width: 70px;
              height: 70px;
              object-fit: contain;
              border: 2px solid transparent;
              transition: .5s;
            }
          }
          .icon-wrap:hover {
            >img {
              background: rgba(224, 239, 255, 0.2);
              box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.14);
              border-radius: 2px;
              border: 2px solid #fff;
            }
          }
        }
      }
      .logo-wrap:hover {
        >img {
          transform: scale(1.1);
        }
      }
      .logo-wrap-hover {
        cursor: default;
      }
      .logo-wrap-hover:hover {
        >img {
          transform: scale(1);
          opacity: 0;
        }
        >pre {
          opacity: 0;
        }
        >object {
          opacity: 1;
          display: flex;
        }
      }
    }
  }
</style>

<template>
  <div class="wrap">
    <div class="left">
      <h1>{{projectItem.title}}</h1>
      <span>{{projectItem.content}}</span>
    </div>
    <div class="right">
      <a class="logo-wrap" :class="{'logo-wrap-hover': !logoItem.url}" :href="logoItem.url?logoItem.url:'javascript:;'" :target="logoItem.url?'_blank':''" v-for="(logoItem,logoIndex) in projectItem.logoList" :key="logoIndex">
        <img :src="logoItem.img" alt="">
        <pre>{{logoItem.name}}</pre>
        <object v-if="!logoItem.url">
          <a class="icon-wrap" :href="iconItem.url?iconItem.url:'javascript:;'" :target="iconItem.url?'_blank':''" v-for="(iconItem,iconIndex) in logoItem.iconList" :key="iconIndex">
            <img :src="iconItem.img" alt="">
            <pre>{{iconItem.name}}</pre>
          </a>
        </object>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  props: ['projectItem']
}
</script>
